<template>
  <section>
    <b-table-simple class="table" borderless striped>
      <thead>
        <tr>
          <th v-can="'FatTissLotEnv1'">
            <Check :value="allChecked" @change="!allChecked ? selectAll() : unSelectAll()" />
          </th>
          <th v-for="(header, index) in headers" :key="index">
            <Ellipsis>{{ header }}</Ellipsis>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="hide"></tr>
        <tr v-for="(tissGuideItem, index) in tissGuideItems" :key="index">
          <td class="check no-print">
            <Check
              :value="!!selectedTissGuideItems.includes(tissGuideItem.id)"
              @change="checked => handleSelectRow(checked, tissGuideItem)"
            />
          </td>

          <td class="patient">
            <div class="procedure-name">
              {{ normalizeTableNumber(tissGuideItem.table_name) }}: Cód. {{ parseItemType(tissGuideItem.item_type) }} {{ tissGuideItem.item.code  }}
            </div>
            <div>
              <Ellipsis>{{ tissGuideItem.description ?? tissGuideItem?.item?.name }}</Ellipsis>
            </div>
          </td>

          <td class="situation">
            <SelectItemSituation 
              v-model="tissGuideItem.status"
              @change="status => onChangeStatus(tissGuideItem, status)"
            />
          </td>

          <td class="column-default">
            {{ parseDate(tissGuideItem.updated_at) }}
          </td>

          <td class="column-default">
            {{ parseNumberToMoney(tissGuideItem.total_value) }}
          </td>

          <td class="column-default">
            <div v-if="tissGuideItem.status !== TissGuideItem.STATUS_ITEM_PARTIALLY_PAID" class="disabled-value">{{ parseNumberToMoney(tissGuideItem.value_paid) }}</div>
            <div v-else :id="`popover-container-${tissGuideItem.id}`">
              <div :id="`value-paid-${tissGuideItem.id}`" class="value-paid">
                {{ parseNumberToMoney(tissGuideItem.value_paid) }}
              </div>
              <b-popover
                :target="`value-paid-${tissGuideItem.id}`"
                triggers="click"
                :show.sync="popoverShow[tissGuideItem.id]"
                placement="bottom"
                :container="`popover-container-${tissGuideItem.id}`"
                :ref="`popover-${tissGuideItem.id}`"
              >
                <div class="popover-content">
                  <money
                    class="form-control"
                    v-model="tissGuideItem.value_paid"
                    @input="value => updateGlossedValue(value, tissGuideItem)"
                  />
                  <Close class="save-icon" @click="onClosePopover(tissGuideItem.id)"/>
                </div>
              </b-popover>
            </div>
          </td>
          <td class="column-default">
            <div class="disabled-value">{{ parseNumberToMoney(tissGuideItem.glossed_value) }}</div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div class="selected-guides" v-if="selectedTissGuideItems.length">
      <p>
        Qtde de guias selecionadas:
        <span>
          <strong>{{ selectedTissGuideItems.length }}</strong> guia(s)
        </span>
      </p>
      <b-button variant="link" @click="unSelectAll">
        Cancelar seleção
      </b-button>
    </div>

    <NoFilterResults
      v-if="!hasResults"
      message="Os filtros aplicados não geraram nenhum resultado."
    />
  </section>
</template>
<script>
import { TissGuideItem } from '@/modules/tiss/manageGuides/utils/statuses'
import { parseItemType, normalizeTableNumber, onChangeStatus } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'guides-table-item',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/components/General/Check'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    SelectItemSituation: () => import('@/modules/tiss/manageGuides/components/SelectItemSituation'),
  },
  props: {
    tissGuideItems: Array,
    selectedTissGuideItems: Array,
    handleSelectRow: Function,
    selectAll: Function,
    unSelectAll: Function
  },
  data() {
    return {
      TissGuideItem,
      selected: [],
      popoverShow: {},
      headers: [
        'Itens',
        'Situação',
        'Atualização',
        'Valor do Item',
        'A receber',
        'Glosado',
      ]
    }
  },
  computed: {
    allChecked() {
      return this.tissGuideItems.length > 0 && this.selectedTissGuideItems.length === this.tissGuideItems.length
    },
    hasResults() {
      return this?.tissGuideItems.length > 0
    },
  },
  methods: {
    parseItemType,
    onChangeStatus,
    normalizeTableNumber,
    parseNumberToMoney,
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    updateGlossedValue(value, tissGuideItem) {
      if (value <= 0) {
        tissGuideItem.glossed_value = 0
        return
      }
      tissGuideItem.glossed_value = tissGuideItem.total_value - value
    },
    onClosePopover(id) {
      this.popoverShow[id] = false
    },
  }
}
</script>

<style lang="scss" scoped>
  .table {
    th {
      vertical-align: middle;
      font-weight: bold;
      color: var(--dark-blue);
    }

    td {
      padding: 0.3rem 0.75rem;
      vertical-align: middle;
    }

    .check {
      width: 30px;
    }

    .items {
      width: 100px;
      text-align: right;
    }

    .situation {
      width: 180px;
      border-left: 1px solid var(--neutral-300);
      border-right: 1px solid var(--neutral-300);

      .dropdown {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--neutral-300);
        border-top: none;
        border-bottom: none;
        text-align: left;
      }
    }

    .column-default {
      width: 110px;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-000);
    margin-right: 4px;
  }
  .arrow {
    fill: var(--blue-500);
  }
  .checkmark {
    width: 25px;
    height: 25px;
  }
  .selected-guides {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    right: 0;
    justify-content: space-between;
    padding: 25px 115px 25px;
    font-size: 16px;
    background: white;
    box-shadow: 16px 0 22px -8px rgba(12, 29, 89, 0.2),
      4px 0 4px 0 rgba(12, 29, 89, 0.1);

    p {
      font-size: 16px;
      color: var(--dark-blue);
    }

    span {
      font-size: 16px;
      color: var(--blue-500);
    }

    button {
      font-size: 16px;
      color: #dc3545;
    }
  }

  .procedure-name {
    font-weight: 700;
    color: var(--blue-500);
  }
  .disabled-value {
    color: var(--type-placeholder);
  }
  .value-paid {
    width: fit-content;
    color: var(--blue-500);
    border-bottom: 1px solid var(--blue-500);
    cursor: pointer;
  }
  .popover-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .save-icon {
    width: 20px;
  }

</style>

<style lang="scss">
#tiss-guides-item-table {
  .situation {
    .btn-link {
      text-align: left;
    }
  }
}
</style>